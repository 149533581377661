import {FormikValues} from 'formik';

import {Api, DbLiteralTypes, DbResponseResultSet, PagingParams, SortingInfo, WhereBuilder} from './Api';
import {SessionData} from './Session';

export const CountrySortInfo: SortingInfo = {
  name: {dataType: 'alpha'},
  admin_id: {dataType: 'alpha'},
  alt_names: {dataType: 'alpha'},
  active_program: {dataType: 'alpha'},
  app_code_id: {dataType: 'num'},
  abbreviation: {dataType: 'alpha'}
}
export interface QueryCountryFilters {
  name?: string
  admin_id?: string
  alt_names?: string
  active_program?: string
  app_code_id?: string
  abbreviation?: string
}

export const RegionSortInfo: SortingInfo = {
  name: {dataType: 'alpha'},
  admin_id: {dataType: 'alpha'},
  alt_names: {dataType: 'alpha'},
  country_name: {dataType: 'alpha'}
}
export interface QueryRegionFilters {
  name?: string
  admin_id?: string
  alt_names?: string
  country_name?: string
  country_id?: string
}

export const SubregionSortInfo: SortingInfo = {
  name: {dataType: 'alpha'},
  admin_id: {dataType: 'alpha'},
  alt_names: {dataType: 'alpha'},
  region_name: {dataType: 'alpha'},
  country_name: {dataType: 'alpha'}
}
export interface QuerySubregionFilters {
  name?: string
  admin_id?: string
  alt_names?: string
  region_name?: string
  region_id?: string
  country_name?: string
  country_id?: string
}

export const DistrictSortInfo: SortingInfo = {
  name: {dataType: 'alpha'},
  admin_id: {dataType: 'alpha'},
  alt_names: {dataType: 'alpha'},
  country_name: {dataType: 'alpha'},
  region_name: {dataType: 'alpha'},
  geoconnect_id: {dataType: 'alpha'}
}
export interface QueryDistrictFilters {
  name?: string
  admin_id?: string
  alt_names?: string
  subregion_name?: string
  subregion_id?: string
  region_name?: string
  region_id?: string
  country_name?: string
  country_id?: string
  geoconnect_id?: string
}

export class DistrictDao extends Api {

  constructor(sessionData: SessionData) { super(sessionData) }

  public async queryCountries(
    filters: QueryCountryFilters,
    paging: PagingParams
  ): Promise<{rows: DbResponseResultSet, count: number}> {

    const queryParams: Record<string, DbLiteralTypes> = {
      ...filters,
      ...paging,
    }

    const whereBuilder = new WhereBuilder()
    whereBuilder.and(filters.name, `name ILIKE '%' || :name || '%'`)
    whereBuilder.and(filters.admin_id, `admin_id::text =  :admin_id`)
    whereBuilder.and(filters.alt_names, 
      `ARRAY_TO_STRING(alt_names, '||') ILIKE '%' || :alt_names || '%'`
    )
    whereBuilder.and(filters.admin_id, `admin_id::text =  :admin_id`)
    whereBuilder.and(filters.active_program && !!filters.active_program.match(/y/i),
      `active_program = true`
    )
    whereBuilder.and(filters.app_code_id, `app_code_id::text =  :app_code_id`)
    whereBuilder.and(filters.abbreviation, `abbreviation ILIKE :abbreviation`)
    const whereClause = whereBuilder.build()

    const propSortInfo = CountrySortInfo[paging.sort]
    const sort = (propSortInfo.columns ?? [paging.sort]).map(s => 
      s + ' ' + (paging.order === 'asc' ? '' : 'desc')
    ).join()

    const cte = `
      select * 
      from public.countries
      ${whereClause}
    `;
    const [rows, countRows] = await Promise.all([
      this.execute(`
        WITH cte AS (${cte}) SELECT * FROM cte ${whereClause}
        ORDER BY ${sort} 
        LIMIT :limit OFFSET :offset
      `, queryParams),
      this.execute(`
        WITH cte AS (${cte}) SELECT COUNT(*) FROM cte ${whereClause}
      `, queryParams)
    ])
    return {rows: rows, count: countRows[0].count as number}
  }

  public async queryRegions(
    filters: QueryRegionFilters,
    paging: PagingParams
  ): Promise<{rows: DbResponseResultSet, count: number}> {

    const queryParams: Record<string, DbLiteralTypes> = {
      ...filters,
      ...paging,
    }

    const whereBuilder = new WhereBuilder()
    whereBuilder.and(filters.name, `regions.name ILIKE '%' || :name || '%'`)
    whereBuilder.and(filters.country_name, `countries.name ILIKE '%' || :country_name || '%'`)
    whereBuilder.and(filters.country_id, `regions.country_id = :country_id`)
    whereBuilder.and(filters.admin_id, `regions.admin_id::text =  :admin_id`)
    whereBuilder.and(filters.alt_names, 
      `ARRAY_TO_STRING(regions.alt_names, '||') ILIKE '%' || :alt_names || '%'`
    )
    whereBuilder.and(filters.admin_id, `regions.admin_id::text =  :admin_id`)
    const whereClause = whereBuilder.build()

    const propSortInfo = RegionSortInfo[paging.sort]
    const sort = (propSortInfo.columns ?? [paging.sort]).map(s => 
      s + ' ' + (paging.order === 'asc' ? '' : 'desc')
    ).join()

    const cte = `
      select regions.*, 
          countries.name as country_name 
        from regions 
        join countries on countries.id = regions.country_id
      ${whereClause}
    `;
    const [rows, countRows] = await Promise.all([
      this.execute(`
        WITH cte AS (${cte}) SELECT * FROM cte
        ORDER BY ${sort} 
        LIMIT :limit OFFSET :offset
      `, queryParams),
      this.execute(`
        WITH cte AS (${cte}) SELECT COUNT(*) FROM cte
      `, queryParams)
    ])
    return {rows: rows, count: countRows[0].count as number}
  }

  public async queryDistricts(
    filters: QueryDistrictFilters,
    paging: PagingParams
  ): Promise<{rows: DbResponseResultSet, count: number}> {

    const queryParams: Record<string, DbLiteralTypes> = {
      ...filters,
      ...paging,
    }

    const whereBuilder = new WhereBuilder()
    whereBuilder.and(filters.name, `districts.name ILIKE '%' || :name || '%'`)
    whereBuilder.and(filters.region_name, `regions.name ILIKE '%' || :region_name || '%'`)
    whereBuilder.and(filters.region_id, `districts.region_id = :region_id`)
    whereBuilder.and(filters.country_name, `countries.name ILIKE '%' || :country_name || '%'`)
    whereBuilder.and(filters.country_id, `regions.country_id = :region_id`)
    whereBuilder.and(filters.admin_id, `districts.admin_id::text =  :admin_id`)
    whereBuilder.and(filters.alt_names, 
      `ARRAY_TO_STRING(districts.alt_names, '||') ILIKE '%' || :alt_names || '%'`
    )
    whereBuilder.and(filters.admin_id, `districts.admin_id::text =  :admin_id`)
    const whereClause = whereBuilder.build()

    const propSortInfo = DistrictSortInfo[paging.sort]
    const sort = (propSortInfo.columns ?? [paging.sort]).map(s => 
      s + ' ' + (paging.order === 'asc' ? '' : 'desc')
    ).join()

    const cte = `
      select districts.*, 
          regions.name as region_name,
          countries.name as country_name,
          countries.id as country_id
        from districts 
        join regions on regions.id = districts.region_id
        join countries on countries.id = regions.country_id
      ${whereClause}
    `;
    const [rows, countRows] = await Promise.all([
      this.execute(`
        WITH cte AS (${cte}) SELECT * FROM cte
        ORDER BY ${sort} 
        LIMIT :limit OFFSET :offset
      `, queryParams),
      this.execute(`
        WITH cte AS (${cte}) SELECT COUNT(*) FROM cte
      `, queryParams)
    ])
    return {rows: rows, count: countRows[0].count as number}
  }

  public async getCountry(id:number): Promise<FormikValues|undefined> {
    const rows = await this.execute(
      "select * from public.countries where id = :id", {id}
    )
    return this.nullsToEmptyStrings(rows[0])
  }

  public async getRegion(id:number): Promise<FormikValues|undefined> {
    const rows = await this.execute(
      `select regions.*, 
          countries.name as country_name 
        from public.regions 
        join public.countries on countries.id = regions.country_id
        where regions.id = :id`, {id}
    )
    return this.nullsToEmptyStrings(rows[0])
  }

  public async getSubRegion(id:number): Promise<FormikValues|undefined> {
    const rows = await this.execute(
      "select * from public.subregions where id = :id", {id}
    )
    return this.nullsToEmptyStrings(rows[0])
  }

  public async getDistrict(id:number): Promise<FormikValues|undefined> {
    const rows = await this.execute(
      `select districts.*,
          regions.name as region_name, 
          regions.country_id as country_id,
          countries.name as country_name 
        from public.districts
        join public.regions on districts.region_id = regions.id 
        join public.countries on countries.id = regions.country_id
        where districts.id = :id`, {id}
    )
    return this.nullsToEmptyStrings(rows[0])
  }

  public async saveCountry(
    id: number | undefined, values: FormikValues
  ): Promise<number> {
    let jsonParam = {
      ...this.emptyStringsToNulls(values),
      id,
    }
    const r = await this.execute("SELECT app.upsert_one('public', 'countries', :json) AS id", {json: JSON.stringify(jsonParam)})
    return r[0].id as number
  }

  public async saveRegion(
    id: number | undefined, values: FormikValues
  ): Promise<number> {
    let jsonParam = {
      ...this.emptyStringsToNulls(values),
      id,
    }
    const r = await this.execute("SELECT app.upsert_one('public', 'regions', :json) AS id", {json: JSON.stringify(jsonParam)})
    return r[0].id as number
  }

  public async saveDistrict(
    id: number | undefined, values: FormikValues
  ): Promise<number> {
    let jsonParam = {
      ...this.emptyStringsToNulls(values),
      id,
    }
    const r = await this.execute("SELECT app.upsert_one('public', 'districts', :json) AS id", {json: JSON.stringify(jsonParam)})
    return r[0].id as number
  }
}

